<template>
  <div>
    <div class="pb-2">
      <a
        href="#"
        @click="$router.push('/kegiatan-bimtek')"
        style="font-size: 14px; color: #0073e9; font-weight: 400"
      >
        <i class="fa fa-chevron-left" style="color: #0073e9"></i>
        Kembali
      </a>
    </div>
    <div class="row justify-content-center">
      <div
        v-if="
          dataJadwal.length != 0 &&
          dataJadwal[0].status_verifikasi != 'Menunggu Verifikasi'
        "
        style="width: 100%"
      >
        <b-card style="width: 100%" no-body>
          <b-tabs v-model="tabIndex" justified style="border-radius: 20px">
            <b-tab :title-link-class="linkClass(0)">
              <template v-slot:title>
                <i class="fa fa-pencil-square-o"></i>
                Pre Test
              </template>
              <div
                v-if="statusPretest.status_pengerjaan !== 'Selesai'"
                class="p-5"
                style="height: 90%"
              >
                <b-card>
                  <h2 class="d-flex my-5">Petunjuk Test</h2>
                  <div class="instructions pl-5">
                    <ol>
                      <li>Bacalah setiap pertanyaan dengan seksama.</li>
                      <li>
                        Pilih jawaban yang menurut Anda paling benar di antara
                        pilihan yang tersedia.
                      </li>
                      <li>
                        Tandai jawaban Anda pada lembar jawaban yang disediakan
                        dengan mengisi penuh lingkaran yang sesuai dengan
                        pilihan Anda.
                      </li>
                      <li>
                        Hanya satu jawaban yang benar untuk setiap pertanyaan.
                      </li>
                    </ol>
                  </div>
                  <div class="d-flex">
                    <h3>Jenis Soal</h3>
                  </div>
                  <div class="d-flex">
                    <P>Pilihan Ganda dan Benar atau Salah</P>
                  </div>
                  <div class="d-flex">
                    <h3>Jumlah Soal</h3>
                  </div>
                  <div class="d-flex">
                    {{ detailKegiatan.jumlah_soal }} Butir
                  </div>
                  <div class="d-flex">
                    <h3>Waktu Pengerjaan</h3>
                  </div>
                  <div>
                    {{ detailKegiatan.waktu_awal_pretest }} sampai dengan
                    {{ detailKegiatan.waktu_akhir_pretest }}
                  </div>
                  <div class="d-flex justify-content-center mt-10">
                    <b-button
                      v-b-modal.modal-xl
                      variant="primary"
                      :disabled="
                        !compareWaktu(
                          detailKegiatan.waktu_awal_pretest,
                          detailKegiatan.waktu_akhir_pretest
                        )
                      "
                    >
                      Mulai Pre-test
                    </b-button>
                  </div>
                </b-card>
              </div>
              <div v-else>
                <b-card>
                  <div class="d-flex justify-content-center mt-10">
                    <p style="color: rgb(23, 162, 184)">
                      Terima kasih telah mengerjakan test yang diselenggarakan
                    </p>
                  </div>
                  <div
                    class="col mt-5"
                    style="display: flex; justify-content: center; top: 60%"
                  >
                    <div class="table-responsive-sm">
                      <table
                        id="example"
                        class="table table-striped table-bordered"
                        style="width: 100%; height: 20%"
                      >
                        <thead>
                          <tr>
                            <th>Nilai Pre Test</th>
                            <th
                              style="vertical-align: middle; text-align: center"
                            >
                              Total Pertanyaan
                            </th>
                            <th
                              style="
                                width: 150px;
                                vertical-align: middle;
                                text-align: center;
                              "
                            >
                              Aksi
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="vertical-align: middle">
                              {{ dataJadwal[0].nilai_pre_test }} / 100
                            </td>
                            <td
                              style="vertical-align: middle; text-align: center"
                            >
                              {{ detailKegiatan.jumlah_soal }}
                            </td>
                            <td
                              style="vertical-align: middle; text-align: center"
                            >
                              <button
                                style="color: rgb(23, 162, 184)"
                                @click="
                                  toPrestest($route.params.id, dataJadwal[0].id)
                                "
                              >
                                Review
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </b-card>
              </div>
            </b-tab>

            <b-tab :title-link-class="linkClass(1)">
              <template v-slot:title>
                <i class="fa fa-list"></i>
                Materi
              </template>
              <div class="accordion mt-5" role="tablist">
                <div
                  v-if="
                    compareWaktuBuka(detailKegiatan.waktu_akhir_pretest) ==
                      false && statusPretest.status_pengerjaan != 'Selesai'
                  "
                  class="p-5"
                  style="height: 90%"
                >
                  <div class="d-flex justify-content-center mt-5">
                    <p style="color: rgb(23, 162, 184)">
                      Silahkan mengikuti alur kegiatan
                    </p>
                  </div>
                </div>
                <div v-else>
                  <b-card
                    v-for="(item, index) in detailKegiatan.materi"
                    :key="index"
                    no-body
                    class="mb-1"
                  >
                    <b-card-header header-tag="header" class="p-2" role="tab">
                      <b-button
                        block
                        @click="toggle(index)"
                        variant="info"
                        style="
                          background-color: #17a2b8;
                          text-align: left;
                          border-color: transparent;
                          white-space: normal;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                        "
                      >
                        <span
                          style="
                            margin: 0;
                            white-space: normal;
                            word-break: break-word;
                            flex-grow: 1;
                          "
                        >
                          {{ item.judul }}
                        </span>
                        <i
                          class="flaticon2-right-arrow"
                          :class="{ rotate: activeIndexes.includes(index) }"
                          style="margin-left: 10px"
                        ></i>
                      </b-button>
                    </b-card-header>

                    <b-collapse
                      :visible="activeIndexes.includes(index)"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>{{ item.deskripsi }}</b-card-text>

                        <div class="table-responsive">
                          <table
                            class="table table-borderless table-vertical-center"
                          >
                            <thead>
                              <tr>
                                <th class="p-0" style="width: 50px"></th>
                                <th class="p-0" style="min-width: 150px"></th>
                                <th class="p-0" style="min-width: 140px"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <template>
                                <tr
                                  v-for="(item, i) in getFileperMateri(item.id)"
                                  :key="i"
                                >
                                  <td class="pl-0 py-5">
                                    <div
                                      class="symbol symbol-50 symbol-light mr-2"
                                    >
                                      <span class="symbol-label">
                                        <i
                                          v-if="
                                            get_url_extension(
                                              item.file_materi
                                            ) == 'pdf' ||
                                            get_url_extension(
                                              item.file_materi
                                            ) == 'PDF'
                                          "
                                          class="fas fa-solid fa-file-pdf"
                                          style="color: red"
                                        ></i>
                                        <i
                                          v-else
                                          class="fas fa-solid fa-video ml-2"
                                          style="color: red"
                                        ></i>
                                      </span>
                                    </div>
                                  </td>
                                  <td class="pl-0">
                                    <a
                                      href="#"
                                      class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                      >{{ item.nama_materi }}</a
                                    >
                                    <span
                                      class="text-muted font-weight-bold d-block"
                                      >{{
                                        format_tanggal(
                                          item.created_at,
                                          "D MMMM YYYY"
                                        )
                                      }}</span
                                    >
                                  </td>

                                  <td class="text-right pr-0">
                                    <b-button
                                      size="l"
                                      class="btn-spacing"
                                      style="
                                        background-color: transparent;
                                        border-color: gray;
                                      "
                                      :href="item.file_materi"
                                      target="blank"
                                    >
                                      Lihat
                                    </b-button>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </b-tab>
            <b-tab :title-link-class="linkClass(2)">
              <template v-slot:title>
                <i class="fa fa-pencil-square-o"></i>
                Post Test
              </template>
              <div
                v-if="
                  compareWaktuBuka(detailKegiatan.waktu_akhir_pretest) ==
                    false && statusPretest.status_pengerjaan != 'Selesai'
                "
                class="p-5"
                style="height: 90%"
              >
                <div class="d-flex justify-content-center mt-10">
                  <p style="color: rgb(23, 162, 184)">
                    Silahkan mengikuti alur kegiatan
                  </p>
                </div>
              </div>
              <div
                v-else-if="statusPostest.status_pengerjaan != 'Selesai'"
                class="p-5"
                style="height: 90%"
              >
                <b-card>
                  <h2 class="d-flex my-5">Petunjuk Test</h2>
                  <div class="instructions pl-5">
                    <ol>
                      <li>Bacalah setiap pertanyaan dengan seksama.</li>
                      <li>
                        Pilih jawaban yang menurut Anda paling benar di antara
                        pilihan yang tersedia.
                      </li>
                      <li>
                        Tandai jawaban Anda pada lembar jawaban yang disediakan
                        dengan mengisi penuh lingkaran yang sesuai dengan
                        pilihan Anda.
                      </li>
                      <li>
                        Hanya satu jawaban yang benar untuk setiap pertanyaan.
                      </li>
                    </ol>
                  </div>
                  <div class="d-flex">
                    <h3>Jenis Soal</h3>
                  </div>
                  <div class="d-flex">
                    <P>Pilihan Ganda dan Benar atau Salah</P>
                  </div>
                  <div class="d-flex">
                    <h3>Jumlah Soal</h3>
                  </div>
                  <div class="d-flex mb-2">
                    {{ detailKegiatan.jumlah_soal }} Butir
                  </div>
                  <div class="d-flex">
                    <h3>Waktu Pengerjaan</h3>
                  </div>
                  <div>
                    {{ detailKegiatan.waktu_awal_posttest }} sampai dengan
                    {{ detailKegiatan.waktu_akhir_posttest }}
                  </div>
                  <div class="d-flex justify-content-center mt-10">
                    <!-- Nanti kayaknya tambahin fungsi biar kalau udah selesai tombol mulainya di disable -->
                    <b-button
                      variant="primary"
                      :disabled="
                        !compareWaktu(
                          detailKegiatan.waktu_awal_posttest,
                          detailKegiatan.waktu_akhir_posttest
                        )
                      "
                      @click="toPosttest($route.params.id, dataJadwal[0].id)"
                    >
                      Mulai Post-test</b-button
                    >
                    <!-- <b-button
                    @click="toPosttest($route.params.id, dataJadwal[0].id)"
                  >
                    Mulai
                  </b-button> -->
                  </div>
                </b-card>
              </div>
              <div v-else>
                <div class="d-flex justify-content-center mt-10">
                  <p style="color: rgb(23, 162, 184)">
                    Terima kasih telah mengerjakan test yang diselenggarakan
                  </p>
                </div>

                <div
                  class="col mt-5"
                  style="display: flex; justify-content: center; top: 60%"
                >
                  <div class="table-responsive-sm">
                    <table
                      id="example"
                      class="table table-striped table-bordered"
                      style="width: 100%; height: 20%"
                    >
                      <thead>
                        <tr>
                          <th>Nilai Pre Test</th>
                          <th>Nilai Post Test</th>
                          <th>Status</th>
                          <th
                            style="vertical-align: middle; text-align: center"
                          >
                            Total Pertanyaan
                          </th>
                          <th
                            style="
                              width: 150px;
                              vertical-align: middle;
                              text-align: center;
                            "
                          >
                            Aksi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="vertical-align: middle">
                            {{ dataJadwal[0].nilai_pre_test }} / 100
                          </td>
                          <td style="vertical-align: middle">
                            {{ dataJadwal[0].nilai_post_test }} / 100
                          </td>
                          <td style="vertical-align: middle">
                            {{ cekStatusNilai }}
                          </td>
                          <td
                            style="vertical-align: middle; text-align: center"
                          >
                            {{ detailKegiatan.jumlah_soal }}
                          </td>
                          <td
                            style="vertical-align: middle; text-align: center"
                          >
                            <button
                              style="color: rgb(23, 162, 184)"
                              @click="
                                toPosttest($route.params.id, dataJadwal[0].id)
                              "
                            >
                              Review
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title-link-class="linkClass(3)">
              <template v-slot:title>
                <i class="fa fa-pencil-square-o"></i>
                Survey
              </template>
              <div
                v-if="statusPostest.status_pengerjaan != 'Selesai'"
                class="p-5"
                style="height: 90%"
              >
                <div class="d-flex justify-content-center mt-10">
                  <p style="color: rgb(23, 162, 184)">
                    Mohon selesaikan post-test terlebih dulu!
                  </p>
                </div>
              </div>
              <div
                v-else-if="
                  dataJadwal[0].file_sertifikat == null ||
                  dataJadwal[0].file_sertifikat == ''
                "
                class="p-5"
                style="height: 90%"
              >
                <b-card
                  bg-variant="success"
                  text-variant="white"
                  title="Pengisian Survey"
                  class="h-100"
                >
                  <b-card-text>
                    <div class="instructions pl-5">
                      <p>
                        Silahkan mengisi survey terlebih dahulu agar dapat
                        mengunduh
                        <strong>Sertifikat Digital</strong>
                      </p>
                    </div>
                  </b-card-text>
                </b-card>
                <b-card>
                  <div>
                    <h5><strong>A. Pelaksanaan</strong></h5>
                  </div>
                  <div class="pl-6">
                    <ol>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Menurut Bapak/Ibu bermanfaatkah kegiatan
                              Peningkatan Kinerja PTSP dan Kinerja PPB
                              Pemerintah Daerah ini?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_a1"
                              :options="options_a1"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Menurut Bapak/Ibu bagaimana tempat dan fasilitas
                              dalam kegiatan Peningkatan Kinerja PTSP dan
                              Kinerja PPB Pemerintah Daerah yang ada?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_a2"
                              :options="options_a2"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Menurut Bapak/Ibu bagaimana kesiapan panitia dalam
                              melaksanakan kegiatan Peningkatan Kinerja PTSP dan
                              Kinerja PPB Pemerintah Daerah?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_a3"
                              :options="options_a3"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div>

                  <div>
                    <h5><strong>B. Materi</strong></h5>
                  </div>
                  <div class="pl-6">
                    <ol>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Menurut Bapak/Ibu bagaimana kesesuaian materi yang
                              disampaikan dalam Peningkatan Kinerja PTSP dan
                              Kinerja PPB Pemerintah Daerah dengan tugas dan
                              fungsi pokok Bapak/Ibu?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_b1"
                              :options="options_b1"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Menurut Bapak/Ibu bagaimana kelengkapan materi
                              yang disampaikan dalam Peningkatan Kinerja PTSP
                              Peningkatan Kinerja PTSP dan Kinerja PPB
                              Pemerintah Daerah?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_b2"
                              :options="options_b2"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div>

                  <div>
                    <h5><strong>C. Narasumber</strong></h5>
                  </div>
                  <div class="pl-6">
                    <ol>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Secara umum, menurut Bapak/Ibu bagaimana
                              penguasaan materi dari narasumber yang
                              menyampaikan paparan materi?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_c1"
                              :options="options_c1"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Menurut Bapak/Ibu bagaimana cara narasumber materi
                              ke-1 dalam menyampaikan materi?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_c2"
                              :options="options_c2"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Menurut Bapak/Ibu bagaimana cara narasumber materi
                              ke-2 dalam menyampaikan materi?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_c3"
                              :options="options_c3"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Menurut Bapak/Ibu bagaimana cara narasumber materi
                              ke-3 dalam menyampaikan materi?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_c4"
                              :options="options_c4"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Menurut Bapak/Ibu bagaimana cara narasumber materi
                              ke-4 dalam menyampaikan materi?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_c5"
                              :options="options_c5"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Menurut Bapak/Ibu bagaimana cara narasumber materi
                              ke-5 dalam menyampaikan materi?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_c6"
                              :options="options_c6"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row px-2">
                          <div class="col-6 mt-2 p-5">
                            <p>
                              Secara umum, menurut Bapak/Ibu bagaimana
                              narasumber dalam menjawab pertanyaan dari peserta
                              Peningkatan Kinerja PTSP dan Kinerja PPB
                              Pemerintah Daerah?
                            </p>
                          </div>
                          <div
                            class="col-6 mt-2 p-5"
                            style="background-color: #ebedf3"
                          >
                            <b-form-radio-group
                              v-model="jawaban_c7"
                              :options="options_c7"
                              class="mb-3"
                              value-field="item"
                              text-field="name"
                              disabled-field="notEnabled"
                              button-variant="default"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div>

                  <div>
                    <h5><strong>D. Saran dan Harapan</strong></h5>
                  </div>
                  <div class="col-12 content-center">
                    <vue-editor v-model="saran" />
                  </div>
                  <div class="px-3 pt-2 float-right">
                    <button
                      type="button"
                      @click="generateCert"
                      style="float: right"
                      class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                    >
                      <i class="fas fa-save"></i> Kirim
                    </button>
                  </div>
                </b-card>
              </div>
              <div v-else class="p-5" style="height: 90%">
                <div class="d-flex justify-content-center mt-10">
                  <p style="color: rgb(23, 162, 184)">
                    Terima kasih telah mengikuti seluruh kegiatan bimtek!
                  </p>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
      <div v-else class="card custom-card gutter-b p-2 col-12">
        <b-card>
          <div class="d-flex">
            <h3>{{ detailKegiatan.nama_kegiatan }}</h3>
          </div>
          <div>
            <p class="float-right">
              <span style="color: red"> * </span>
              <strong>
                {{
                  format_tanggal(detailKegiatan.tanggal_mulai, "D MMM YYYY") +
                  " sampai dengan " +
                  format_tanggal(detailKegiatan.tanggal_selesai, "D MMM YYYY")
                }}
              </strong>
            </p>
          </div>
          <div class="mt-5">
            Materi:
            <ul class="ml-5 mr-5">
              <li v-for="(row, i) in detailKegiatan.materi" :key="i">
                {{ row.judul }}
              </li>
            </ul>
          </div>
          <div class="d-flex justify-content-center">
            <b-badge
              size="xl"
              variant="warning"
              v-if="
                dataJadwal.length != 0 &&
                dataJadwal[0].status_verifikasi == 'Menunggu Verifikasi'
              "
            >
              <span class="p-3">Menunggu Verifikasi dari Admin</span>
            </b-badge>
            <b-button v-else variant="primary" @click="enroll">
              Enroll Kegiatan
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
    <b-modal id="modal-xl" size="xl" title="Cek Pakta Integritas" hide-footer>
      <div>
        <div @scroll="onScroll" ref="scrollableDiv" class="pl-3">
          <p>
            Saya bertindak untuk dan atas nama sendiri sebagai peserta Bimbingan
            Teknis Peningkatan Penilaian Kinerja Pelayanan Terpadu Satu Pintu
            dan Kinerja Percepatan Pelaksanaan Berusaha Pemerintah Daerah
            menyatakan bersedia mengikuti bimbingan teknis sampai dengan acara
            selesai dan mematuhi ketentuan yang berlaku dalam pelaksanaannya.
          </p>
          <p>Demikian pernyataan ini saya buat dengan sebenar-benarnya.</p>
        </div>
        <b-form-checkbox
          id="checkbox-1"
          v-model="status"
          name="checkbox-1"
          value="accepted"
          unchecked-value="not_accepted"
          size="sm"
        >
          Saya menyetujui pakta integritas
        </b-form-checkbox>
      </div>
      <div class="mt-5"></div>
      <div>
        <b-button
          class="float-right"
          :variant="statusAccepted ? 'success' : 'second'"
          :disabled="!statusAccepted"
          @click="toPrestest($route.params.id, dataJadwal[0].id)"
        >
          Mulai
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import format_date from "@/core/utils/helper";
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";
import KegiatanBimtek from "./kegiatanBimtek.vue";

export default {
  name: "detail-kegiatan",
  components: { VueEditor },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      user: JSON.parse(localStorage.getItem("user")),
      status: false,
      canCheck: false,
      tabIndex: 0,
      detailKegiatan: {},
      dataJadwal: [],
      cekStatusNilai: "",
      statusPretest: {
        status_pengerjaan: null,
      },
      statusPostest: {
        status_pengerjaan: null,
      },
      dataFileMateri: [],
      activeIndexes: [],
      dataAnswer: [],
      id_peserta: null,
      id_kegiatan: null, // Assuming id_kegiatan is fixed for this example
      jawaban_a1: null,
      jawaban_a2: null,
      jawaban_a3: null,

      jawaban_b1: null,
      jawaban_b2: null,

      jawaban_c1: null,
      jawaban_c2: null,
      jawaban_c3: null,
      jawaban_c4: null,
      jawaban_c5: null,
      jawaban_c6: null,
      jawaban_c7: null,

      saran: "",

      options_a1: [
        { item: "Sangat bermanfaat", name: "Sangat bermanfaat" },
        { item: "Bermanfaat", name: "Bermanfaat" },
        { item: "Cukup Bermanfaat", name: "Cukup Bermanfaat" },
        { item: "Tidak Bermanfaat", name: "Tidak Bermanfaat" },
        { item: "Sangat tidak bermanfaat", name: "Sangat tidak bermanfaat" },
      ],
      options_a2: [
        { item: "Sangat baik dan lengkap", name: "Sangat baik dan lengkap" },
        { item: "Baik dan lengkap", name: "Baik dan lengkap" },
        { item: "Cukup baik dan lengkap", name: "Cukup baik dan lengkap" },
        {
          item: "Tidak baik dan tidak lengkap",
          name: "Tidak baik dan tidak lengkap",
        },
        {
          item: "Sangat tidak baik dan sangat tidak lengkap",
          name: "Sangat tidak baik dan sangat tidak lengkap",
        },
      ],
      options_a3: [
        { item: "Sangat siap", name: "Sangat siap" },
        { item: "Siap", name: "Siap" },
        { item: "Cukup Siap", name: "Cukup Siap" },
        {
          item: "Tidak Siap",
          name: "Tidak Siap",
        },
        {
          item: "Sangat tidak Siap",
          name: "Sangat tidak Siap",
        },
      ],

      options_b1: [
        { item: "Sangat sesuai", name: "Sangat sesuai" },
        { item: "Sesuai", name: "Sesuai" },
        { item: "Cukup Sesuai", name: "Cukup Sesuai" },
        {
          item: "Tidak Sesuai",
          name: "Tidak Sesuai",
        },
        {
          item: "Sangat tidak sesuai",
          name: "Sangat tidak sesuai",
        },
      ],
      options_b2: [
        { item: "Sangat lengkap", name: "Sangat lengkap" },
        { item: "Lengkap", name: "Lengkap" },
        { item: "Cukup lengkap", name: "Cukup lengkap" },
        {
          item: "Tidak lengkap",
          name: "Tidak lengkap",
        },
        {
          item: "Sangat tidak lengap",
          name: "Sangat tidak lengap",
        },
      ],

      options_c1: [
        { item: "Sangat menguasai", name: "Sangat menguasai" },
        { item: "Menguasai", name: "Menguasai" },
        { item: "Cukup menguasai", name: "Cukup menguasai" },
        {
          item: "Tidak menguasai",
          name: "Tidak menguasai",
        },
        {
          item: "Sangat tidak menguasai",
          name: "Sangat tidak menguasai",
        },
      ],
      options_c2: [
        { item: "Sangat menarik", name: "Sangat menarik" },
        { item: "Menarik", name: "Menarik" },
        { item: "Cukup menarik", name: "Cukup menarik" },
        {
          item: "Tidak menarik",
          name: "Tidak menarik",
        },
        {
          item: "Sangat tidak menarik",
          name: "Sangat tidak menarik",
        },
      ],
      options_c3: [
        { item: "Sangat menarik", name: "Sangat menarik" },
        { item: "Menarik", name: "Menarik" },
        { item: "Cukup menarik", name: "Cukup menarik" },
        {
          item: "Tidak menarik",
          name: "Tidak menarik",
        },
        {
          item: "Sangat tidak menarik",
          name: "Sangat tidak menarik",
        },
      ],
      options_c4: [
        { item: "Sangat menarik", name: "Sangat menarik" },
        { item: "Menarik", name: "Menarik" },
        { item: "Cukup menarik", name: "Cukup menarik" },
        {
          item: "Tidak menarik",
          name: "Tidak menarik",
        },
        {
          item: "Sangat tidak menarik",
          name: "Sangat tidak menarik",
        },
      ],
      options_c5: [
        { item: "Sangat menarik", name: "Sangat menarik" },
        { item: "Menarik", name: "Menarik" },
        { item: "Cukup menarik", name: "Cukup menarik" },
        {
          item: "Tidak menarik",
          name: "Tidak menarik",
        },
        {
          item: "Sangat tidak menarik",
          name: "Sangat tidak menarik",
        },
      ],
      options_c6: [
        { item: "Sangat menarik", name: "Sangat menarik" },
        { item: "Menarik", name: "Menarik" },
        { item: "Cukup menarik", name: "Cukup menarik" },
        {
          item: "Tidak menarik",
          name: "Tidak menarik",
        },
        {
          item: "Sangat tidak menarik",
          name: "Sangat tidak menarik",
        },
      ],
      options_c7: [
        { item: "Sangat jelas", name: "Sangat jelas" },
        { item: "Jelas", name: "Jelas" },
        { item: "Cukup jelas", name: "Cukup jelas" },
        {
          item: "Tidak jelas",
          name: "Tidak jelas",
        },
        {
          item: "Sangat tidak jelas",
          name: "Sangat tidak jelas",
        },
      ],
    };
  },
  mounted() {
    this.loadKegiatan();
    this.loadFileMateri();
    this.loadJadwalPeserta().then(() => {
      this.cekPretest();
      this.cekPostest();
    });
  },
  computed: {
    statusAccepted() {
      return this.status === "accepted";
    },
  },
  methods: {
    onScroll() {
      const scrollableDiv = this.$refs.scrollableDiv;
      const isAtBottom =
        scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
        scrollableDiv.scrollHeight;
      if (isAtBottom) {
        this.canCheck = true;
      }
    },
    format_tanggal(value, format) {
      return format_date(value, format);
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
    loadKegiatan() {
      this.axios
        .get(
          this.url + "/jadwal_kegiatan?filter=id,=," + this.$route.params.id,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.detailKegiatan = response.data.data[0];
          for (var i = 0; i < this.detailKegiatan.jumlah_soal; i++) {
            this.dataAnswer.push("");
          }

          console.log(this.dataAnswer);
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.detailKegiatan.nama_kegiatan },
          ]);
        });
    },
    loadJadwalPeserta() {
      return this.axios
        .get(
          this.url +
            "/jadwal_peserta?filter=id_kegiatan,=," +
            this.$route.params.id +
            ";id_user,=," +
            this.user.id,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.dataJadwal = response.data.data;
          this.id_peserta = response.data.data[0].id;
          this.id_kegiatan = response.data.data[0].id_kegiatan;

          // Menentukan status berdasarkan nilai pre-test dan post-test
          if (
            parseFloat(this.dataJadwal[0].nilai_pre_test) >
            parseFloat(this.dataJadwal[0].nilai_post_test)
          ) {
            this.cekStatusNilai = "Turun";
          } else if (
            parseFloat(this.dataJadwal[0].nilai_pre_test) ===
            parseFloat(this.dataJadwal[0].nilai_post_test)
          ) {
            this.cekStatusNilai = "Sama";
          } else if (
            parseFloat(this.dataJadwal[0].nilai_pre_test) <
            parseFloat(this.dataJadwal[0].nilai_post_test)
          ) {
            this.cekStatusNilai = "Naik";
          }
        });
    },

    loadFileMateri() {
      this.axios
        .get(this.url + "/file_materi", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.dataFileMateri = response.data.data;
        });
    },

    getFileperMateri(id) {
      return this.dataFileMateri.filter((post) => {
        return post["id_materi"]
          .toString()
          .toLowerCase()
          .trim()
          .includes(id.toString().toLowerCase().trim());
      });
    },

    enroll() {
      Swal.fire({
        title: "Enroll Kegiatan",
        text: "Apakah Anda yakin ingin enroll kegiatan?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Enroll",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(
              this.url + "/jadwal_peserta",
              {
                id_kegiatan: this.$route.params.id,
                id_user: this.user.id,
              },
              {
                headers: {
                  xth: this.token,
                },
              }
            )
            .then((response) => {
              Swal.fire({
                title: "Berhasil",
                text: "Anda berhasil enroll kegiatan",
                icon: "success",
              });
              this.loadJadwalPeserta();
            })
            .catch((e) => {
              Swal.fire({
                title: "Gagal!",
                text: "Terjadi kesalahan",
                icon: "error",
              });
            });
        }
      });
    },
    toPrestest(id_kegiatan, id_jadwal) {
      this.axios
        .get(
          this.url +
            "/jawaban_peserta?filter=id_kegiatan,=," +
            id_kegiatan +
            ";id_peserta,=," +
            id_jadwal +
            ";jenis_ujian,=,Pre Test",
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          const dataRes = response.data.data;

          if (dataRes.length == 0) {
            console.log(dataRes);
            // var dataAnswer = []

            this.axios
              .post(
                this.url + "/jawaban_peserta",
                {
                  id_peserta: id_jadwal,
                  jenis_ujian: "Pre Test",
                  jawaban: this.dataAnswer,
                },
                {
                  headers: {
                    xth: this.token,
                  },
                }
              )
              .then((response) => {
                this.$router.push({
                  path: "/kegiatan-bimtek/pretest/" + this.$route.params.id,
                  query: {
                    scheduleID: id_jadwal,
                    answerID: response.data.data.id,
                  },
                });
              });
          } else {
            this.$router.push({
              path: "/kegiatan-bimtek/pretest/" + this.$route.params.id,
              query: { scheduleID: id_jadwal, answerID: dataRes[0].id },
            });
          }
        });
    },

    toPosttest(id_kegiatan, id_jadwal) {
      this.axios
        .get(
          this.url +
            "/jawaban_peserta?filter=id_kegiatan,=," +
            id_kegiatan +
            ";id_peserta,=," +
            id_jadwal +
            ";jenis_ujian,=,Post Test",
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          const dataRes = response.data.data;

          if (dataRes.length == 0) {
            console.log(dataRes);
            // var dataAnswer = []

            this.axios
              .post(
                this.url + "/jawaban_peserta",
                {
                  id_peserta: id_jadwal,
                  jenis_ujian: "Post Test",
                  jawaban: this.dataAnswer,
                },
                {
                  headers: {
                    xth: this.token,
                  },
                }
              )
              .then((response) => {
                this.$router.push({
                  path: "/kegiatan-bimtek/posttest/" + this.$route.params.id,
                  query: {
                    scheduleID: id_jadwal,
                    answerID: response.data.data.id,
                  },
                });
              });
          } else {
            this.$router.push({
              path: "/kegiatan-bimtek/posttest/" + this.$route.params.id,
              query: { scheduleID: id_jadwal, answerID: dataRes[0].id },
            });
          }
        });
    },

    compareWaktu(a, b) {
      let sekarang = new Date();
      let data_a = new Date(a);
      let data_b = new Date(b);
      if (sekarang >= data_a && sekarang <= data_b) {
        return true;
      } else {
        return false;
      }
    },

    compareWaktuBuka(a) {
      let sekarang = new Date();
      let data_a = new Date(a);
      if (sekarang >= data_a) {
        return true;
      } else {
        return false;
      }
    },

    // Materi

    toggle(index) {
      const position = this.activeIndexes.indexOf(index);
      if (position !== -1) {
        this.activeIndexes.splice(position, 1);
      } else {
        this.activeIndexes.push(index);
      }
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-primary", "active-icon"];
      } else {
        return ["bg-secondary", "text-dark", "deactive-icon"];
      }
    },
    generateCert() {
      if (
        !this.jawaban_a1 ||
        !this.jawaban_a2 ||
        !this.jawaban_a3 ||
        !this.jawaban_b1 ||
        !this.jawaban_b2 ||
        !this.jawaban_c1 ||
        !this.jawaban_c2 ||
        !this.jawaban_c3 ||
        !this.jawaban_c4 ||
        !this.jawaban_c5 ||
        !this.jawaban_c6 ||
        !this.jawaban_c7 ||
        !this.saran
      ) {
        Swal.fire({
          title: "Gagal!",
          text: "Harap mengisi semua pertanyaan survei",
          icon: "error",
        });
        return;
      }

      Swal.fire({
        title: "Kirim Survey",
        text: "Apakah Anda yakin ingin mengirim survey?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, kirim!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          const surveyData = {
            id_peserta: this.id_peserta,
            id_kegiatan: this.id_kegiatan,
            jawaban_a1: this.jawaban_a1,
            jawaban_a2: this.jawaban_a2,
            jawaban_a3: this.jawaban_a3,
            jawaban_b1: this.jawaban_b1,
            jawaban_b2: this.jawaban_b2,
            jawaban_c1: this.jawaban_c1,
            jawaban_c2: this.jawaban_c2,
            jawaban_c3: this.jawaban_c3,
            jawaban_c4: this.jawaban_c4,
            jawaban_c5: this.jawaban_c5,
            jawaban_c6: this.jawaban_c6,
            jawaban_c7: this.jawaban_c7,
            saran_harapan: this.saran,
          };
          this.axios
            .get(
              this.url +
                "/jadwal_peserta/generate_sertifikat/" +
                this.id_peserta,
              {
                headers: {
                  xth: this.token,
                },
              }
            )
            .then((response) => {
              return this.axios.post(this.url + "/survey_bimtek", surveyData, {
                headers: {
                  xth: this.token,
                },
              });
            })
            .then((response) => {
              Swal.fire({
                title: "Berhasil mengirim survey",
                text: "Sertifikat dapat diunduh di halaman utama",
                icon: "success",
              });
              this.$router.push("/dashboard");
            })
            .catch((e) => {
              console.log(e);
              Swal.fire({
                title: "Gagal!",
                text: "Terjadi kesalahan",
                icon: "error",
              });
            });
        }
      });
    },
    cekPretest() {
      this.axios
        .get(
          this.url +
            "/jawaban_peserta?filter=id_peserta,=," +
            this.id_peserta +
            ";jenis_ujian,=,Pre%20Test",
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          if (response.data.data.length != 0) {
            const dataResponse = response.data.data[0];
            this.statusPretest = dataResponse;
          }
        });
    },
    cekPostest() {
      this.axios
        .get(
          this.url +
            "/jawaban_peserta?filter=id_peserta,=," +
            this.id_peserta +
            ";jenis_ujian,=,Post%20Test",
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          if (response.data.data.length != 0) {
            this.statusPostest = response.data.data[0];
          }
        });
    },
  },
};
</script>

<style scoped>
.rotate-icon {
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(90deg);
}

.btn-spacing {
  margin-right: 10px;
}

/* Gaya Ikon Tidak Aktif */
.deactive-icon .fa {
  color: black !important; /* Ganti dengan warna yang Anda inginkan */
  padding: 5px;
  /* background-color: #e4e6ef; */
  margin-right: 5px;
  border-radius: 5px;
  font-size: 14px;
}

/* Gaya Ikon Aktif */
.active-icon .fa {
  color: #3699ff; /* Ganti dengan warna yang Anda inginkan */
  padding: 5px;
  /* background-color: #3699ff; */
  margin-right: 5px;
  border-radius: 5px;
  font-size: 14px;
}
</style>